import React, {  useContext, useReducer, useState, useEffect, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import {Collapse, Dropdown} from 'react-bootstrap';
import {MenuList} from './Menu';
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import '../../../dashboard/CSS/navigationcustomization.css'
import { useToggle } from "../../../context/ToggleContext";
import { useActive } from "../../../context/ActiveContext";
import { useReferral } from '../../../context/ReferralContext' 

import { useGoogleAuthentication } from "../../../context/GoogleAuthenticationContext"; 
let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1]


const SideBar = () => {
  var d  = new Date();
	const {
		iconHover,
		sidebarposition,
		headerposition,
		sidebarLayout,
		ChangeIconSidebar,
  
	} = useContext(ThemeContext);
  const {state, setState, handleMenuActive} = useActive()
  const [isProfileValid, setIsProfileValid] = useState(true) 
   
  const {toggle} = useToggle()
  const { showReferral } = useReferral() 

  const { hasEnabledGoogleAuthentication, enableGoogleAuthenticationHandler } = useGoogleAuthentication() 
  // console.log(hasEnabledGoogleAuthentication) 
  useEffect(()=>{
    MenuList.forEach(function(item){
      document.getElementById(item.id)?.addEventListener('mouseenter', ()=>{
        document.querySelector('#menu > li:hover > a > span > span > img')?.setAttribute('src', item.active_icon)
      })
      document.getElementById(item.id)?.addEventListener('mouseleave', ()=>{
        if(state.active === item.title) {
          document.querySelector(`#menu > li > a#${item.id} > span > span > img`)?.setAttribute('src', item.active_icon)
        }
        else {
          document.querySelector(`#menu > li > a#${item.id} > span > span > img`)?.setAttribute('src', item.normal_icon)
        }
      
      })
    })
   
    return ()=>{
      MenuList.forEach((item)=>{
        document.getElementById(item.id)?.removeEventListener('mouseenter', ()=>{})
        document.getElementById(item.id)?.removeEventListener('mouseleave', ()=>{})

      })
    }
    })
    
   

	  let handleheartBlast = document.querySelector('.heart');
	  function heartBlast(){
		  return handleheartBlast.classList.toggle("heart-blast");
	  }
  
 	const [hideOnScroll, setHideOnScroll] = useState(true)
	useScrollPosition(
		({ prevPos, currPos }) => {
		  const isShow = currPos.y > prevPos.y
		  if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)

 
	
	const handleSubmenuActive = (status) => {		
		setState({activeSubmenu : status})
		if(state.activeSubmenu === status){
			setState({activeSubmenu : ""})			
		}    
	}
  
    return (
        <div className="deznav">
          <PerfectScrollbar className="deznav-scroll">          
            <ul className="metismenu" id="menu" >
              {MenuList.map((data, index)=>{
                let menuClass = data.classsChange;
                  if(menuClass === "menu-title"){
                    return(
                        <li className={menuClass}  key={index} >{data.title}</li>
                    )
                  }else{
                    return(				
                      <li className={` ${ state.active === data.title ? 'mm-active' : ''}`}
                        key={index} 
                      >                        
                        {data.content && data.content.length > 0 ?
                            <>
                              <Link to={"#"} 
                                className="has-arrow"
                                onClick={() => {handleMenuActive(data.title)}}
                              >								
                                  {data.iconStyle}
                                  <span className="nav-text">{data.title}</span>
                              </Link>
                              <Collapse in={state.active === data.title ? true :false}>
                                  <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                    {data.content && data.content.map((data,index) => {									
                                      return(	
                                          <li key={index}
                                            className={`${ state.activeSubmenu === data.title ? "mm-active" : ""}`}                                    
                                          >
                                            {data.content && data.content.length > 0 ?
                                                <>
                                                  <Link to={data.to} className={data.hasMenu ? 'has-arrow' : ''}
                                                    onClick={() => { handleSubmenuActive(data.title)}}
                                                  >
                                                    {data.title}
                                                  </Link>
                                                  <Collapse in={state.activeSubmenu === data.title ? true :false}>
                                                      <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                                        {data.content && data.content.map((data,index) => {
                                                          return(	
                                                            <>
                                                              <li key={index}>
                                                                <Link className={`${path === data.to ? "mm-active" : ""}`} to={data.to}>{data.title}</Link>
                                                              </li>
                                                            </>
                                                          )
                                                        })}
                                                      </ul>
                                                  </Collapse>
                                                </>
                                              :
                                              <>
                                                
                                                <Link to={data.to}>
                                                  {data.title}
                                                </Link>
                                              </>
                                            }
                                            
                                          </li>                                        
                                      )
                                    })}
                                  </ul>
                                </Collapse>
                            </>
                        :
                          <Link 
                            hidden={
                              data.title === 'Referral' ? 
                            (!showReferral ? true : false) :
                            (data.title === 'Google Authentication') ?  
                            (hasEnabledGoogleAuthentication ? true : false) : 
                             false
                          }       
                            to={data.to}  
                            onClick={() => {handleMenuActive(data.title)}} 
                            id={data.id}  
                          >  
                            <span className={`link-logo-label-container ${toggle ? 'toggeled-icons-containers' : ''}`}>
                              {
                                state.active === data.title ? 
                                <span style={{marginRight: '2px'}}><img src={data.active_icon} /></span> : 
                                <span style={{marginRight: '2px'}}><img src={data.normal_icon} /></span> 

                              }
                              <span className={`nav-text link-label ${data.title === 'Profile' ? 'ml-2' : ''}`}>{data.title} </span>
                              </span>
                          </Link>
                        }
                        
                      </li>	
                    )
                  }
                })} 
            </ul> 
			    </PerfectScrollbar>
        </div>
    );
  
}

export default SideBar;

