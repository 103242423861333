import React from 'react'

import { Field, ErrorMessage } from 'formik'

import ErrorInput from './ErrorInput'

import './CSS/textinput.css' 
import Button from './Button' 
const TextInput = ({label, name, id, error, touched, inputtype, onKeyUp, placeholder, click, currency, styles}) => {
  return (
    <div className='form_control'>
        {label && <label htmlFor={id} className='form_label'><span>{label.search(/\(Optional\)$/) !== -1 ? label.substr( 0, label.search(/\(Optional\)$/) - 1) : label}</span ><span hidden={label.search(/\(Optional\)$/) === -1} style={{fontWeight: '400',
    color: '#656464'}}> (Optional)</span></label>}  
        <Field input={inputtype} name={name} id={id} 
        className={`register-input ${error && touched ? 'error' : ''} ${!error && touched ? 'correct' : ''} ${currency ? 'pAmountCurrency' : ''} ${styles ? styles : ''}`}  
        onKeyUp={onKeyUp && onKeyUp} inputMode={`${id === 'email' ? 'email' : id ==='authenticationCode' ? 'numeric' : 'text'}`} placeholder={placeholder} /> 
        <ErrorMessage name={name} component={ErrorInput} />
        {id === 'uid' && <img src='/images/dashboard/sidebar/userguide_na.svg' alt='a circle containing character i in lower case' className='clarificationIcon' onClick={click} /> }             
        {id === 'uid' && <Button imagesrc='/images/dashboard/sidebar/userguide_na.svg' alt='a circle containing character i in lower case' styles='clarificationIcon' click={click} /> }  
        {(currency === 'usd' && id === 'topup_amount') && <div className={`amountCurrency ${currency === 'usd' ? 't-41' : ''}`}>$</div>}                        
        {(currency === '$' && id === 'topup_amount') && <div className={`amountCurrency`}>{currency}</div>}                         
    </div>
  )
}

export default TextInput 



