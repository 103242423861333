import React, { createContext, useState, useCallback, useContext } from "react";

export const GoogleAuthenticationContext = createContext(null);

const GoogleAuthenticationContextProvider = (props) => {
    const [hasEnabledGoogleAuthentication, setHasEnabledGoogleAuthentication] = useState(false)   

    const enableGoogleAuthenticationHandler = useCallback(function () {
        setHasEnabledGoogleAuthentication(true)
    }, [])
    const disableGoogleAuthenticationHandler = useCallback(function () {
        setHasEnabledGoogleAuthentication(false) 
    }, []) 

    return (
        <GoogleAuthenticationContext.Provider
          value={{
            hasEnabledGoogleAuthentication,
            enableGoogleAuthenticationHandler,
            disableGoogleAuthenticationHandler 
        }}
        >
          {props.children}
        </GoogleAuthenticationContext.Provider> 
     )
}

export default GoogleAuthenticationContextProvider 

export function useGoogleAuthentication () {
    const { hasEnabledGoogleAuthentication, enableGoogleAuthenticationHandler, disableGoogleAuthenticationHandler } = useContext(GoogleAuthenticationContext) 
    return { hasEnabledGoogleAuthentication, enableGoogleAuthenticationHandler, disableGoogleAuthenticationHandler }   
}


 
