import React, { useState, useEffect, useCallback } from 'react'
import { Accordion } from 'react-bootstrap' 
import './CSS/transactionsVisualizer.css'  
import moment from 'moment'     
import { useSearchParams } from "react-router-dom";
import SearchArea from './SearchArea';
import Pagination from './Pagination'; 
const TransactionsVisulizer = (props) => {
    let [searchParams, setSearchParams] = useSearchParams({'p': 1}); 
    const [filterName, setFilterName] = useState('') 

    const changeFilterInputValueHandler = useCallback(function (e) {
        setFilterName(e.currentTarget.value) 
        setSearchParams(params => {
            params.set("p", 1);  
            return params;
          })
    }, [])    

    const resetFilterInputValueHandler = useCallback(function () {
          if(filterName !== '') {
            setFilterName('')
            if(document.getElementById('filterCardTrxByName')) {
                document.getElementById('filterCardTrxByName').value = ''     
            }

            setSearchParams(params => {
                params.set("p", 1);  
                return params;
            })
          } 

    }, [filterName])  


    useEffect(()=>{
        if(document.getElementById('filterCardTrxByName')) {
            document.getElementById('filterCardTrxByName').value = filterName    
        }

        if(searchParams.get('p') !== 1) {
            setSearchParams(params => {
                params.set("p", 1);  
                return params;
              })
        }
    }, [props.parentFilter])


    const allData = props.data.map((item)=> {
        if(item['merchantName'] === '') {
            return {...item, 'merchantName': 'TRANS_FEES'}   
        } else {
            return item 
        }})
        .filter((item)=> item['status'] !== null)
        .filter(function(item) { 
            if(item['merchantName'].toLowerCase().indexOf(filterName.toLowerCase()) === -1){
                 return false
            } else { 
                return true
            }
        })

    const data = props.data.map((item)=> {
        if(item['merchantName'] === '') {
            return {...item, 'merchantName': 'TRANS_FEES'}   
        } else {
            return item 
        }})
        .filter((item)=> item['status'] !== null)                                                      
        .filter(function(item) { 
            if(item['merchantName'].toLowerCase().indexOf(filterName.toLowerCase()) === -1){
                    return false
            } else { 
                return true
            }
        })
        .slice(((searchParams.get('p') * 10) - 10), ((searchParams.get('p') * 10)))


        const rest_division = allData.length %  10 
        let paginationArr = []

        if(rest_division === 0) {
            for(let i = 0; i < Math.floor(allData.length / 10); i++) {
                paginationArr[i] = i 
            }
        } else {
            for(let i = 0; i <= Math.floor(allData.length / 10) ; i++) {
                paginationArr[i] = i  
            }
        }


        const paginationHandler = useCallback(function (e) {
            setSearchParams(params => {
                params.set("p", parseInt(e.currentTarget.innerHTML)); 
                return params;
                })      
        }, [])  

                
              
  return ( 
    <>
        {((props.data?.length > 0) && props.data !== null && props.data !== undefined ) && <>
        <SearchArea changeFilterInputValueHandler={changeFilterInputValueHandler} resetFilterInputValueHandler={resetFilterInputValueHandler} /> 
        <div className='transactions-wrapper-body'>
            <ul className='transactions-wrapper-body-list'>
                {
                data.length > 0 ? (data.map((item, index)=>{   
                    return (
                            
                            <Accordion key={index}>  
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                            <li  className='transactions-wrapper-body-list-transaction-data-item'>
                                                <figure className='transaction-status-image'>
                                                    {   ((item['transactionResponse'] === '80') && item['transactionType'] === 'Card_Load')  ?                     
                                                        <img src='/images/dashboard/mycard/transaction-green.svg' alt='green arrow: credited' />  :
                                                        <img src='/images/dashboard/mycard/transaction-red.svg' alt='red arrow: debited' />        
                                                    }
                                                </figure>
                                                <div>
                                                    <span className='transaction-origin'>{`${(item['merchantName'] === '') ? 'TRANS_FEES' : item['merchantName']}`}</span> 
                                                    <div className='transaction-date'><span>{`${moment(item['dateOfTransaction']).format("MMM DD, YYYY")}`}</span><span>{`${moment(item['dateOfTransaction']).format("HH:mm")}`}</span></div>
                                                    <span className='transaction-value'>{item['authAmount']} {item['authCurrencyCode'].toLowerCase() === 'usd' ? '$': item['authCurrencyCode']}</span>  
                                                    <span className={`transaction-status text-align-right ${item['status'] === 'DECLINED' ? 'declinedColor' : ''}`}>{item['status'] === 'COMPLETION' ? 'Completed': item['status'] === 'DECLINED' ? 'Declined' : ''}</span> 
                                                </div>
                                            </li>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='transactionDetailsWrapper'>
                                            <div className='transactionDetailItem'>
                                                <span className='transactionDetailItemLabel'>Transaction ID</span>
                                                <span style={{whiteSpace: 'normal', wordBreak: 'break-all'}} className='transactionDetailItemValue text-black font-w500'>{item['transactionId']}</span>
                                            </div> 
                                            <div className='transactionDetailItem'>
                                                <span className='transactionDetailItemLabel'>RNN</span>
                                                <span className='transactionDetailItemValue text-black font-w500'>
                                                    {item['retrievalReferenceNumber']} 
                                                </span>
                                            </div> 
                                            <div className='transactionDetailItem'>
                                                <span className='transactionDetailItemLabel'>Country</span>
                                                <span className='transactionDetailItemValue text-black font-w500'>
                                                    {`${item['transactionCountry'] ? item['transactionCountry'] : '--'}`}    
                                                </span> 
                                            </div>
                                            <div className='transactionDetailItem'>
                                                <span className='transactionDetailItemLabel'>Paid Date</span>
                                                <span className='transactionDetailItemValue text-black font-w500'>{`${new Date(item['dateOfTransaction']).toLocaleString('default', { month: 'long' })} ${(new Date(item['dateOfTransaction']).getDate() > 9) ? new Date(item['dateOfTransaction']).getDate() : '0'+new Date(item['dateOfTransaction']).getDate()}, ${new Date(item['dateOfTransaction']).getFullYear()}`}</span>  
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>)
                    
                    })
                ) :
                    <div className='noRecords'>No Matching Records</div>                    
                }

            </ul>
        </div>
        {allData.length > 0 ? <Pagination data={paginationArr} paginationHandler={paginationHandler} searchParams={searchParams} /> : ''}       
        </> 
        }
        {
            (props.data?.length === 0 || props.data === null || props.data === undefined ) && <div className='noRecords'>No Records</div> 
        }
    </>
  )
    
}

export default TransactionsVisulizer 
                                                                                                                                                                                                                                                                                     