import React from 'react'
import './CSS/howitworks.css'

const Howitworks = () => {
  return (
    <section id='howitworks'>
        <div className='howitworks-container'>
            <figure className='howitworks-container-image-content'>
                <img src='/images/landingpage/body/Frame 7 (4).png' alt='cards' className='web-cards' draggable='false' />
            </figure>
            <div className='howitworks-container-text-content'>
                <h2 className='howitworks-container-title'>How It Works</h2> 
                <div className='step-container' id='step1'>
                    <img src='/images/landingpage/body/Vector (4).svg' alt='step 1' className='img-step' draggable='false' />
                    <div className='step-content-wrapper'>
                        <div className='step-content-wrapper-title'>Sign Up and Get Your Card:</div>
                        <div className='step-content-wrapper-description'>Register for a PAYTAPS account and order your card  online.</div>
                    </div>
                </div>
                <div className='step-container'id='step2'>
                    <img src='/images/landingpage/body/6379125 1.svg' alt='step 2' className='img-step' draggable='false' />
                    <div className='step-content-wrapper'>
                        <div className='step-content-wrapper-title'>Top Up Easily</div>
                        <div className='step-content-wrapper-description'>Load your card with USDT and spend in USD.</div>
                    </div>
                </div>
                <div className='step-container' id='step3'>
                    <img src='/images/landingpage/body/tabler_world.svg' alt='step 3' className='img-step' draggable='false' />
                    <div className='step-content-wrapper'>
                        <div className='step-content-wrapper-title'>Use Globally</div>
                        <div className='step-content-wrapper-description'>Make secure transactions worldwide with Visa Card's extensive  network.</div>
                    </div>
                </div>
                <div className='step-container' id='step4'>
                    <img src='/images/landingpage/body/tabler_chart-infographic.svg' alt='step 4' className='img-step' draggable='false' />
                    <div className='step-content-wrapper'>
                        <div className='step-content-wrapper-title'>Monitor Your Spending</div>
                        <div className='step-content-wrapper-description'>Use the PAYTAPS dashboard to track your transactions  and get real-time alerts.</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Howitworks