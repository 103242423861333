import React from 'react'
import './CSS/faq.css' 
import Accordion from 'react-bootstrap/Accordion'; 
import { Link } from 'react-router-dom'; 
const FAQ = () => {
  return (
    <section className='faqSection'>
        <div className='faqContainer'>
            <h2 className='faqTitle'>FAQ</h2> 
            <div className='faqContent'>
                <Accordion> 
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>How do I create a Paytaps card?</Accordion.Header>
                        <Accordion.Body>
                            <span>
                            To create a Paytaps card, first, sign up for a Paytaps account. After successfully creating your account, deposit at least 60 USDT into your wallet. Once your wallet has a minimum of 60 USDT, you can order your Paytaps card directly from your dashboard by completing the order form. You will receive an email confirming that your card request has been received, and another email to notify you once your card is ready for use. 
                        </span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header> What currency is accepted for deposits, what are the minimum deposit requirements, and how can I deposit into my Paytaps account?</Accordion.Header>
                        <Accordion.Body>
                            <span> 
                                
                                You can deposit into your Paytaps account using USDT. <br />
                                The minimum deposit for the first transaction is 60 USDT, while subsequent deposits require a minimum of 100 USDT. <br /> 
                                <span style={{marginTop: '16px'}}>You have two options for making a deposit:</span>
                                <ol>
                                    <li><span style={{color: 'black', fontWeight: '600'}}>Blockchain Transfer:</span> <Link to='/login'>Log in</Link>  <span className='unsetProp'>to your account and navigate to the </span><Link to='dashboard'>wallet dashboard</Link><span className='unsetProp'>. Click on "Deposit" to find your USDT deposit address. Copy the address, go to your external wallet to initiate the transfer, and enter the transaction hash (HASH) in your Paytaps account to confirm the deposit.</span></li>
                                    <li><span style={{color: 'black', fontWeight: '600'}}>CIEx Exchange Deposit:</span> <span className='unsetProp'>If you prefer to deposit from the CIEx exchange, you just need to enter your CIEx wallet UID and specify the amount you wish to deposit into your Paytaps account. </span></li>                      
                                </ol>
                                The deposited funds will appear in your Paytaps wallet as USDT. There are no fees for deposits. 
                            </span> 
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>How do I top up my Paytaps card, and in what currency will the funds appear?</Accordion.Header>
                        <Accordion.Body>
                            <span>
                            To top up your Paytaps card, <Link to='/login'>log in</Link> to your Paytaps account, navigate to the <Link to='/dashboard'>card dashboard</Link>, and select the top-up option. You can top up using USDT. The minimum top-up amount is the equivalent of 100 USD.
                            </span> 
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>How can I retrieve my Paytaps card details?</Accordion.Header>
                        <Accordion.Body>
                            <span>
                            To retrieve your Paytaps card details, <Link to='/login'>log in</Link> to your Paytaps account, go to your <Link to='/dashboard'>card dashboard</Link>, and click on "Get Card Details." You will receive an email with a verification code. Enter this code on the dashboard to view your card number, expiration date, and other necessary details.
                            </span> 
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>How can I use my Paytaps card for tap-and-pay purchases?</Accordion.Header>
                        <Accordion.Body>
                            <span>
                            To make purchases with just a tap, link your Paytaps card to your digital wallet, such as Google Pay, Apple Pay, or Samsung Pay. Once linked, you can use your Paytaps card for seamless in-store and online transactions.
                            </span> 
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header> What should I do if I forgot my Paytaps account password?</Accordion.Header>
                        <Accordion.Body>
                            <span>
                            If you forgot your Paytaps account password, go to the <Link to='/login'>login page</Link> and click on the "Forgot Password" link. You will receive an email with a One-Time Password (OTP). Use this OTP on the password reset page to set a new password.
                            </span> 
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>How do I reset my Paytaps account password?</Accordion.Header>
                        <Accordion.Body>
                            <span>
                            To reset your Paytaps password, <Link to='/login'>log in</Link> to your Paytaps account and go to the <Link to='/profile'>profile section</Link>. Enter your current password, then enter and confirm your new password. If the old password is correct and both new passwords match, your password will be updated. You will receive an email confirming the password change.
                            </span> 
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>How does Two-Factor Authentication (2FA) work for Paytaps, and how can I configure Google Authenticator?</Accordion.Header>
                        <Accordion.Body>
                            <span>
                                To ensure your account’s security, Paytaps uses Two-Factor Authentication (2FA) during login. Here’s how it works:
                                <ol>
                                    <li>
                                        <span style={{color: 'black', fontWeight: '600'}}>Email OTP:</span><span className='unsetProp'> When you log in to your Paytaps account, a One-Time Password (OTP) will be sent to your registered email address.Enter the OTP in the login screen to access your account.
                                            <br/>If you don’t see the email in your inbox, please check your spam or junk folder.
                                        </span>
                                    </li> 
                                    <li>
                                        <span style={{color: 'black', fontWeight: '600'}}>Google Authenticator (Optional):</span> 
                                        <span className='unsetProp'> You can also configure <strong>Google Authenticator</strong> to receive the 2FA code directly in the app. Follow these steps to set it up:
                                            <ul className='stepsConfigGoogleAuthentication'>
                                                <li>Log in to your Paytaps account.</li>
                                                <li>Go to Google Authentication Section.</li>
                                                <li>Open the Google Authenticator app on your device.</li>
                                                <li>Add a new account by scanning the QR code provided in your Paytaps account.</li>
                                                <li>Once set up, you’ll receive your 2FA codes directly in the Google Authenticator app whenever you log in.</li>  
                                            </ul>
                                        </span>
                                    </li>                        
                                </ol>  
                            </span> 
                             
                        </Accordion.Body>
                    </Accordion.Item> 
                </Accordion>
            </div> 
        </div>
    </section>
  )
}

export default FAQ