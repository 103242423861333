// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bindGoogleAuthenticationContainer {
    width: 100%;
    background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0 3.9px 3.9px 0 #523F690D;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 27px; 
    margin-bottom: 37px; 
}
.bindGoogleAuthenticationInnerContainer {
    display: flex;
    gap: 15px; 
}
.bindGoogleAuthenticationContainer .bindGoogleAuthenticationTitle {
    font: 600 20px / 34px var(--font-poppins); 
    color: #000000;
}
.bindGoogleAuthenticationContainer .bindGoogleAuthenticationDescription {
    font: 400 16px / 21px var(--font-poppins); 
    color: #7E7E7E; 
    margin-bottom: 0;    
}
.bindGoogleAuthenticationContainer button {
    height: 50px;
    background: #1EAAE7;
    width: 180px;
    border-radius: 12px;
    font: 700 20px / 22px var(--font-poppins);
    letter-spacing: -0.2px; 
    color: #FFFFFF; 
}



@media screen and (max-width: 1200px) {
    .bindGoogleAuthenticationContainer {
        flex-direction: column;
    }
    .bindGoogleAuthenticationInnerContainer {
        width: 100%;
        margin-bottom: 20px;
    }
    .bindGoogleAuthenticationContainer button {
        width: 100%; 
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/CSS/bindgoogleauthentication.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,qCAAqC;IACrC,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,SAAS;AACb;AACA;IACI,yCAAyC;IACzC,cAAc;AAClB;AACA;IACI,yCAAyC;IACzC,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,yCAAyC;IACzC,sBAAsB;IACtB,cAAc;AAClB;;;;AAIA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,WAAW;QACX,mBAAmB;IACvB;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".bindGoogleAuthenticationContainer {\n    width: 100%;\n    background: #FFFFFF;\n    border-radius: 6px;\n    box-shadow: 0 3.9px 3.9px 0 #523F690D;\n    display: flex;\n    justify-content: space-between;\n    align-items: center; \n    padding: 27px; \n    margin-bottom: 37px; \n}\n.bindGoogleAuthenticationInnerContainer {\n    display: flex;\n    gap: 15px; \n}\n.bindGoogleAuthenticationContainer .bindGoogleAuthenticationTitle {\n    font: 600 20px / 34px var(--font-poppins); \n    color: #000000;\n}\n.bindGoogleAuthenticationContainer .bindGoogleAuthenticationDescription {\n    font: 400 16px / 21px var(--font-poppins); \n    color: #7E7E7E; \n    margin-bottom: 0;    \n}\n.bindGoogleAuthenticationContainer button {\n    height: 50px;\n    background: #1EAAE7;\n    width: 180px;\n    border-radius: 12px;\n    font: 700 20px / 22px var(--font-poppins);\n    letter-spacing: -0.2px; \n    color: #FFFFFF; \n}\n\n\n\n@media screen and (max-width: 1200px) {\n    .bindGoogleAuthenticationContainer {\n        flex-direction: column;\n    }\n    .bindGoogleAuthenticationInnerContainer {\n        width: 100%;\n        margin-bottom: 20px;\n    }\n    .bindGoogleAuthenticationContainer button {\n        width: 100%; \n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
