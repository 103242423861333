import { redirect } from 'react-router-dom';  
import {  API_HOST } from '../config/env';
const API_URL = API_HOST() + "api/";
export function register(payload) {
    return fetch(API_URL+'signup', {
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export function login(payload) {
   return fetch(API_URL+'login', {
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export function saveToken(payloadToken, payloadRefresh) {
    // console.log(payload.split('.'))
    const splittedToken = payloadToken.split('.')                                                                                              
    localStorage.setItem('u_t_h', splittedToken[0])
    localStorage.setItem('u_t_p', splittedToken[1])
    localStorage.setItem('u_t_s', splittedToken[2])
    localStorage.setItem('u_r_t', payloadRefresh) 
    // return localStorage.setItem('u_t', payload)
}

export function joinToken() {
    if(localStorage.getItem('u_t_h') && localStorage.getItem('u_t_p') && localStorage.getItem('u_t_s')) {
        return [localStorage.getItem('u_t_h'), localStorage.getItem('u_t_p'), localStorage.getItem('u_t_s')].join('.')
    } else {
        return null
    }
}

export function logout() {
    // console.log(joinToken()) 
    // const x = joinToken()
    // killToken()
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
        if(decoded_payload['exp']*1000 < new Date().valueOf()) {
            killToken()
            return redirect('/login')
        }
    } else {
        return redirect('/login') 
    }
     
    return fetch(API_URL+'logout', { 
        method: 'POST', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken()  

        }
    })
}
export function killToken() {
    if(localStorage.getItem('u_t_h') && localStorage.getItem('u_t_p') && localStorage.getItem('u_t_s') && localStorage.getItem('m_t_u') && localStorage.getItem('o_t_p_t') && localStorage.getItem('t_u_f')) {
        localStorage.removeItem('u_t_h')
        localStorage.removeItem('u_t_p')
        localStorage.removeItem('u_t_s')
        localStorage.removeItem('m_t_u')
        localStorage.removeItem('o_t_p_t')
        localStorage.removeItem('t_u_f') 
        localStorage.removeItem('c_l') 
        localStorage.removeItem('m_f_d')
        localStorage.removeItem('m_n_d')
        localStorage.removeItem('t_d_d') 
        localStorage.removeItem('u_r_t')   
        localStorage.removeItem('t_u_f_50_100')
        localStorage.removeItem('t_u_f_100')
        localStorage.removeItem('m_b')                                  
        return {kill: true}
    } 
    else {
        return null
    }
}
// export function formatError (errorResponse) {
//     switch (errorResponse) {
//         case 'Invalid credentials.':
//             return "Invalid credentials"
//             break;
//         case 'Email already exists':
//             return "Email already exists"
//             break;
//         case 'EMAIL_NOT_FOUND':
//            return "Email not found"
//            break;
//         case 'INVALID_PASSWORD':
//             return "Invalid Password"
//             break;
//         case 'USER_DISABLED':
//             return 'User Disabled';
//         default:
//             return '';
//     }
// }
export function formatError (errorResponse) {
    switch (errorResponse) {
        case 'Invalid credentials.':
            return "Invalid credentials"
            break;
        case 'Email already exists':
            return { title: 'This email is already associated with an account', subtitle: 'Please try logging in or use a different email to sign up.'} 
            break; 
        case 'Invalid referral code.': 
            return {title: 'Invalid Referral Code', subtitle: 'The code you entered doesn’t seem to be valid. Please double-check the code and try again'} 
        case 'Referral code is expired.': 
            return {title: 'Referral Code Expired', subtitle: 'It seems this referral code is no longer valid. Please reach out to the person who referred you for a new code or contact our support team for assistance.'}  
        case 'EMAIL_NOT_FOUND':
           return "Email not found"
           break;
        case 'INVALID_PASSWORD':
            return "Invalid Password"
            break;
        case 'USER_DISABLED':
            return 'User Disabled';
        default:
            return '';
    }
}


export function requestCard(payload) { 
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'request-card', {
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
} 

export function userBalance() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'client/wallet/balance', {
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function generateAddress() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    
    return fetch(API_URL+'address/wallet/generete', {
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}
export function regenerateAddress() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'address/wallet/regenerete', {
        method: 'GET', 
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function initialDeposit(payload) {
    // address/wallet/confirmation
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'address/wallet/confirmation', {
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}
export function normalDeposit(payload) {
    // address/wallet/confirmation
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'request/deposit/confirmation', {
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function findRequestCard() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'find-request-card', {
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function getProfileData() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'me', {    
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}
// /api/find-request-card
 

export function getCardBalance() {
    // console.log(decoded_payload, decoded_payload['exp']*1000, (new Date().valueOf()), decoded_payload['exp']*1000 < (new Date().valueOf()))
    
    try {
        if(localStorage.getItem('u_t_p')) {
            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
        if(decoded_payload['exp']*1000 < new Date().valueOf()) {
            killToken()
            return redirect('/login')
        }
        } else {
            return redirect('/login') 
        }          
        try {
            return fetch(API_URL+'card-balance', {    
                method: 'GET', 
                // body: JSON.stringify(payload),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + joinToken(),
                    "Accept": "application/json"
                }
            })
        } catch(err) {
        }
    } catch(err) {
        console.log(err) 
        if(localStorage.getItem('u_t_p')) {
            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
        if(decoded_payload['exp']*1000 < new Date().valueOf()) {
            killToken()
            return redirect('/login')
        }
        } else {
            return redirect('/login') 
        } 
    }
}

export function getCardPreviousTransactions() {
    
    try {
        if(localStorage.getItem('u_t_p')) {
            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
            if(decoded_payload['exp']*1000 < new Date().valueOf()) {
                killToken()
                return redirect('/login')
            }
        } else {
            return redirect('/login') 
        } 
        return fetch(API_URL+'card-transactions-Previos', {    
            method: 'GET', 
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + joinToken(),
                "Accept": "application/json"
            }
        })
    } catch(err) {
        if(localStorage.getItem('u_t_p')) {
            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
            if(decoded_payload['exp']*1000 < new Date().valueOf()) {
                killToken()
                return redirect('/login')
            }
        } else {
            return redirect('/login') 
        }
    } 
} 
// card-transactions 
export function getCardTransactions() {
    
    try {
        if(localStorage.getItem('u_t_p')) {
            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
            if(decoded_payload['exp']*1000 < new Date().valueOf()) {
                killToken()
                return redirect('/login')
            }
        } else {
            return redirect('/login') 
        } 
        return fetch(API_URL+'card-transactions', {    
            method: 'GET', 
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + joinToken(),
                "Accept": "application/json"
            }
        })
    } catch(err) {
        if(localStorage.getItem('u_t_p')) {
            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
            if(decoded_payload['exp']*1000 < new Date().valueOf()) {
                killToken()
                return redirect('/login')
            }
        } else {
            return redirect('/login') 
        }
    } 
}
// http://localhost:8741/api/card-transactions-Previos 

// export function getCardTopupHistory() {
    
//     try {
//         if(localStorage.getItem('u_t_p')) {
//             const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
//             if(decoded_payload['exp']*1000 < new Date().valueOf()) {
//                 killToken()
//                 return redirect('/login')
//             } 
//         } else {
//             return redirect('/login') 
//         } 
//         return fetch(API_URL+'card-transactions-topup-history', {    
//             method: 'GET', 
//             // body: JSON.stringify(payload),
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": 'Bearer ' + joinToken(),
//                 "Accept": "application/json"
//             }
//         }) 
//     } catch(err) { 
//         if(localStorage.getItem('u_t_p')) {
//             const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
//             if(decoded_payload['exp']*1000 < new Date().valueOf()) {
//                 killToken()
//                 return redirect('/login')
//             } 
//         } else {
//             return redirect('/login') 
//         } 
//     }
// }

export function getCardTopupHistory() {
    
    try {
        if(localStorage.getItem('u_t_p')) {
            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
            if(decoded_payload['exp']*1000 < new Date().valueOf()) {
                killToken()
                return redirect('/login')
            } 
        } else {
            return redirect('/login') 
        } 
        return fetch(API_URL+'all-request-topup', {    
            method: 'GET', 
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + joinToken(),
                "Accept": "application/json"
            }
        }) 
    } catch(err) { 
        if(localStorage.getItem('u_t_p')) {
            const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
            if(decoded_payload['exp']*1000 < new Date().valueOf()) {
                killToken()
                return redirect('/login')
            } 
        } else {
            return redirect('/login') 
        } 
    }
}
// http://localhost:8741/api/card-transactions-topup-history 
export function getAllDeposits() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'request/deposit', {    
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function getConfig() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }    
    
    return fetch(API_URL+'config', {    
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function generateOTP() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'otp/generate', {    
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function verifyOTP(payload) {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }

    return fetch(API_URL+'otp/verify-otp', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function getcardDetails() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'card-details', {    
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function convertToUSDT(payload) {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
        if(decoded_payload['exp']*1000 < new Date().valueOf()) {
            killToken()
            return redirect('/login')
        }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'convert', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function requestTopUp(payload) {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
        if(decoded_payload['exp']*1000 < new Date().valueOf()) {
            killToken()
            return redirect('/login')
        }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'request-topup', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}
// http://localhost:8741/api/card/info 
export function cardInfo() {
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
        if(decoded_payload['exp']*1000 < new Date().valueOf()) {
            killToken()
            return redirect('/login')
        }
    } else {
        return redirect('/login') 
    }
    return fetch(API_URL+'card/info', {    
        method: 'GET', 
        // body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function generateOTPForgetPassword(payload) {
    // if(localStorage.getItem('u_t_p')) {
    //     const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    // if(decoded_payload['exp']*1000 < new Date().valueOf()) {
    //     killToken()
    //     return redirect('/login')
    // }
    // } else {
    //     return redirect('/login') 
    // }
    return fetch(API_URL+'otp/forget-password', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
           // "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function verifyOTPForgetPassword(payload) {
    // if(localStorage.getItem('u_t_p')) {
    //     const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    // if(decoded_payload['exp']*1000 < new Date().valueOf()) {
    //     killToken()
    //     return redirect('/login')
    // }
    // } else {
    //     return redirect('/login') 
    // }

    return fetch(API_URL+'otp/verify-otp-forget-password', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
           // "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function forgetPassword(payload) {
    // if(localStorage.getItem('u_t_p')) {
    //     const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    // if(decoded_payload['exp']*1000 < new Date().valueOf()) {
    //     killToken()
    //     return redirect('/login')
    // }
    // } else {
    //     return redirect('/login') 
    // }

    return fetch(API_URL+'forget-password', {    
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
           // "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json"
        }
    })
}

export function updatePassword(payload) { 
    if(localStorage.getItem('u_t_p')) {
        const decoded_payload = JSON.parse(atob(localStorage.getItem('u_t_p'))) 
    if(decoded_payload['exp']*1000 < new Date().valueOf()) {
        killToken()
        return redirect('/login')
    }
    } else {
        return redirect('/login') 
    }

    return fetch(API_URL+'reset-password', {     
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + joinToken(),
            "Accept": "application/json" 
        }
    })
} 



export function refreshToken(payload) { 

    return fetch(API_URL+'token/refresh', {     
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json" 
        }
    })
}

export function cardApproval(payload) { 

    return fetch(API_URL+'card-approval', {     
        method: 'POST', 
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
}

export function generateReferralCode() { 

    return fetch(API_URL+'referrals/event/generet_code', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
}

export function getReferralCode() { 

    return fetch(API_URL+'referral/code', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
}

export function getReferralStats() {  

    return fetch(API_URL+'referrals/stats-activity-event', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
}


export function getAllReferralRewards() {  

    return fetch(API_URL+'referrals/rewards', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
}

export function getAllInvitedPeople() {  

    return fetch(API_URL+'referrals/people-invited', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
}


export function getGeneralReferralRate() {  

    return fetch(API_URL+'referrals/referral-rate-success', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
}

export function getGlobalReferralDataActivities() {  

    return fetch(API_URL+'referrals/list-acitvity-referral', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
}


export function shareReferralLinkByEmail(payload) {  

    return fetch(API_URL+'referral/share/link', {     
        method: 'POST',
        body: JSON.stringify(payload), 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
}

export function getTransactionsOverviewMetrics() {  

    return fetch(API_URL+'metric/transaction_overview', {     
        method: 'GET',
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    }) 
}

export function getTopupRequestsMetrics() {  

    return fetch(API_URL+'metric/topup_requests', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
}

export function getDepositRequestsMetrics() {  

    return fetch(API_URL+'metric/deposit_requests', {     
        method: 'GET', 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
            "Authorization": 'Bearer ' + joinToken() 
        }
    })
}

export function verifyEmail(payload) {  

    return fetch(API_URL+'token/verify-email', {     
        method: 'POST',  
        body: JSON.stringify(payload), 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
        }
    })
}

export function resendVerificationEmail(payload) {  

    return fetch(API_URL+'token/resend/verify-email', {     
        method: 'POST',  
        body: JSON.stringify(payload), 
        headers: {
            "Content-Type": "application/json", 
            "Accept": "application/json", 
        }
    })
}

export function securedLogin(payload) { 
    return fetch(API_URL+'log-in', {
         method: 'POST', 
         body: JSON.stringify(payload),
         headers: {
             "Content-Type": "application/json"
         }
     })
} 

export function securedLoginCheck(payload) { 
    return fetch(API_URL+'login-check', {
         method: 'POST', 
         body: JSON.stringify(payload),
         headers: {
             "Content-Type": "application/json"
         }
     })
}  

export function getSecretKey() { 
    return fetch(API_URL+'user/qr/google', {
         method: 'GET',  
         headers: {
             "Content-Type": "application/json", 
             "Accept": "application/json", 
             "Authorization": 'Bearer ' + joinToken() 
         }
     })
}

export function completeLinkToGoogleAuth(payload) { 
    return fetch(API_URL+'user/google', {
         method: 'POST',  
         body: JSON.stringify(payload),  
         headers: {
             "Content-Type": "application/json", 
             "Accept": "application/json", 
             "Authorization": 'Bearer ' + joinToken() 
         }
     })
}

export function getLoginType(payload) { 
    return fetch(API_URL+'user/type/login', {
         method: 'GET',  
         headers: {
             "Content-Type": "application/json", 
             "Accept": "application/json", 
             "Authorization": 'Bearer ' + joinToken() 
         }
     })
}
