import React, { useState, useEffect, useCallback } from 'react' 
import ReferralWelcomeImage from '../components/ReferralWelcomeImage' 
import GenerateLink from '../components/GenerateLink' 
import InviteShareLinkSpendings from '../components/InviteShareLinkSpendings' 
import './CSS/welcomereferral.css' 
import Loader from '../jsx/pages/Loader/Loader'
import { useLoaderData } from 'react-router-dom'
import { generateReferralCode } from '../security/AuthService' 
import { useReferral } from '../context/ReferralContext' 
const WelcomeReferral = () => {
  const [loadingState,setLoadingState] = useState(true)
  const loader_code_data = useLoaderData() 
  const [referralData, setReferralData] = useState({referral_code: loader_code_data.code, expiration_date: loader_code_data.expiration_date}) 
  const { showReferral, handleShowReferral } = useReferral()     

  useEffect(()=>{
    setLoadingState(false) 
    if(!showReferral) {
      handleShowReferral() 
    } 
  }, [referralData.referral_code]) 

  const generateCode = useCallback( async function() {
    const generate_code = await generateReferralCode()
    const generated_code = await generate_code.json()
    setReferralData({...referralData, referral_code: generated_code['data'][0]['referralCode'], expiration_date: generated_code['data'][0]['expireAt']['date']})   
  }, [])  

  return (
    <>
      <div className='welcomeReferralContainer'>
        <ReferralWelcomeImage />   
        {((!loadingState && referralData.referral_code === null) ) && <GenerateLink generateCode={generateCode} />} 
        {(loadingState && referralData.referral_code === null) && <div className='waitLoadingProfileData h-50vh'><div>Please wait...</div><Loader /></div>}  
      </div>
      {(!loadingState && referralData.referral_code !== null) && <InviteShareLinkSpendings referralCode={referralData.referral_code} codeExpirationDate={referralData.expiration_date} />}  
      {(loadingState && referralData.referral_code !== null) && <div className='waitLoadingProfileData h-50vh'><div>Please wait...</div><Loader /></div>}  

    </>
    
  )
}

export default WelcomeReferral

