import React from 'react'
import './CSS/checkemail.css' 
import { Modal } from 'react-bootstrap'
import Button from '../components/Button' 
import { resendVerificationEmail } from '../security/AuthService' 
import { useLocation } from 'react-router-dom' 
const CheckEmail = ({show, handleClose, title}) => {
    const location = useLocation() 
    async function resendVerificationEmailHandler () {
        try {
            if(sessionStorage.getItem('u_e')) {
                const post_resend_verification_email = await resendVerificationEmail({email: sessionStorage.getItem('u_e')})
                const result_post_resend_verification_email = await post_resend_verification_email.json()
                if(result_post_resend_verification_email.message === 'Success') {
                    handleClose() 
                }
            } 
        } catch(err) {

        }
    }
  return (
    <>
        <Modal show={show} className='verification' backdrop='static' keyboard='false'> 
            <button id='closeVerification' onClick={handleClose}><img src='/images/dashboard/2fa/Vector (8).svg' alt='a grey cross mark' /></button> 
            <div className='verifyAccountCheckEmailInnerContainer'>
                <div className='verifyAccountCheckEmailContentWrapper'>
                    <img src='/images/registration/logo.png' 
                         alt='An open gray envelope, in front of which is a paper rocket containing two cards, and on the top right of the envelope is a blue shield containing the Paytabs logo. Under the envelope and the paper rocket is a black line (representing the ground) and a plant with blue leaves'
                         className='d-block m-auto imgWidth'  
                    />  
                    <div className='checkEmailTitle'>{title}</div> 
                    <div className='checkEmailDescription'>We sent a verification link to your email</div>
                    {(title === 'Your account is not verified yet') && <button id='sendVerificationEmail' onClick={resendVerificationEmailHandler}>Send again</button> } 
                    {(title === 'Check your email') && <button id='closeVerificationEmail' onClick={handleClose}>Close</button> }           
                </div>
            </div>
        </Modal> 
    </>
  )
}

export default CheckEmail  

