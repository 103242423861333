import React, { createContext, useState, useCallback, useContext } from "react";

export const ReferralContext = createContext(null);

const ReferralContextProvider = (props) => {
    const [showReferral, setShowReferral] = useState(false)  

     const handleShowReferral = useCallback(()=>{
        setShowReferral(true) 
     }, [])

     const handleNotShowReferral = useCallback(()=>{
        setShowReferral(false) 
     }, []) 

     return (
        <ReferralContext.Provider
          value={{
            showReferral,
            handleShowReferral,
            handleNotShowReferral 
        }}
        >
          {props.children}
        </ReferralContext.Provider>
     )
}

export default ReferralContextProvider

export function useReferral () {
    const { showReferral, handleShowReferral, handleNotShowReferral } = useContext(ReferralContext)
    return { showReferral, handleShowReferral, handleNotShowReferral }  
}


