import React from "react";
import ReactApexChart from "react-apexcharts";
class TransferBarGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      series: [{
			name: 'Deposit',
			data: props.transactionOverviewMetrcis.allDepositsPerMonth                                             

        }, {
			name: 'Top up', 
			data: props.transactionOverviewMetrcis.allTopupPerMonth 

        }],
      options: {
        chart: {   
		  width: '100%', 
          type: "bar",
          toolbar: {
            show: false,     
			}
        },
		plotOptions: { 
			bar: {
			  borderRadius: 8,
			  horizontal: false,  
			  columnWidth: '57%', 
			},
		  },
		colors: ['#E7BB1E', '#1EAAE7'] , 
        legend: {
			show: true,  
			fontSize: '12px',
			labels: {
				colors: '#000000', 
				}, 
				markers: {
				width: 18,
				height: 18,
				strokeWidth: 0,
				strokeColor: '#fff',
				fillColors: undefined,
				radius: 12,	
			},
			
		},
		fill: {
          opacity: 1,
		  colors: ['#E7BB1E', '#1EAAE7'] 
        },
        dataLabels: {
          enabled: false, 
        },
        stroke: {
          show: true,
          width: 4,
          colors: ['transparent'], 
		 
        },
        grid:{
			borderColor: '#eee',
		},
        
        xaxis: { 
			categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],  
			labels: {
				style: {
					colors: '#787878',
					fontSize: '13px',
					fontFamily: 'poppins',
					fontWeight: 100,
					cssClass: 'apexcharts-xaxis-label',
				},
			},
			crosshairs: {
				show: false,
			} 
		},
		yaxis: {
			labels: {
				offsetX: -13,    
				style: {
					colors: '#787878',
					fontSize: '13px',
					fontFamily: 'poppins',
					fontWeight: 100,
					cssClass: 'apexcharts-xaxis-label',
				},
			},
		},
         tooltip: {
          y: {
            formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
			  if(seriesIndex === 0) {
				return "$ " + val
			  } else if(seriesIndex === 1) {
				return "$ " + val 
			  }
            }
          }
        }, 
		responsive: [
			{
			  breakpoint: 488,
			  options: {
				chart: {  
				  width: '100%', 
				},
				plotOptions: { 
					bar: {
					  horizontal: true,   
					},
				  },
					yaxis: {
						labels: {
							offsetX: 0
						}, 
				},
			  }, 
			  
			}
		  ]
      },
    };
  } 
componentDidUpdate(previousProps, previousState) {
	if(this.props.listMonths.toString() !== previousState['options']['xaxis']['categories'].toString()) {
		this.setState({
			series: [{
				name: 'Deposit',
				data: this.props.transactionOverviewMetrcis.allDepositsPerMonth                                             
		}, {
			name: 'Top up', 
			data: this.props.transactionOverviewMetrcis.allTopupPerMonth  

        }], 
		options: {
			chart: {   
			  width: '100%', 
			  type: "bar",
			  toolbar: {
				show: false,     
				}
			},
			plotOptions: { 
				bar: {
				  borderRadius: 8,
				  horizontal: false,  
				  columnWidth: '57%', 
				},
			  },
			colors: ['#E7BB1E', '#1EAAE7'] , 
			legend: {
				show: true,  
				fontSize: '12px',
				labels: {
					colors: '#000000', 
					}, 
					markers: {
					width: 18,
					height: 18,
					strokeWidth: 0,
					strokeColor: '#fff',
					fillColors: undefined,
					radius: 12,	
				},
				
			},
			fill: {
			  opacity: 1,
			  colors: ['#E7BB1E', '#1EAAE7'] 
			},
			dataLabels: {
			  enabled: false, 
			},
			stroke: {
			  show: true,
			  width: 4,
			  colors: ['transparent'], 
			 
			},
			grid:{
				borderColor: '#eee',
			},
			
			xaxis: {
				categories: this.props.listMonths,     	
				labels: {
					style: {
						colors: '#787878',
						fontSize: '13px',
						fontFamily: 'poppins',
						fontWeight: 100,
						cssClass: 'apexcharts-xaxis-label',
					},
				},
				crosshairs: {
					show: false, 
				} 
			},
			yaxis: {
				labels: {
					offsetX: -13,    
					style: {
						colors: '#787878',
						fontSize: '13px',
						fontFamily: 'poppins',
						fontWeight: 100,
						cssClass: 'apexcharts-xaxis-label',
					},
				},
			},
			 tooltip: {
			  y: {
				formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
				  if(seriesIndex === 0) {
					return "$ " + val
				  } else if(seriesIndex === 1) {
					return "$ " + val 
				  }
				}
			  }
			}, 
			responsive: [
				{
				  breakpoint: 488,
				  options: {
					chart: {  
					  width: '100%', 
					},
					plotOptions: { 
						bar: {
						  horizontal: true,   
						},
					  },
						yaxis: {
							labels: {
								offsetX: 0
							}, 
					},
				  }, 
				  
				}
			  ]
		  },
		}, );
	}
	
  } 
   
	render() {
		return (
			<div id="chart">   
				<ReactApexChart
				  options={this.state.options}
				  series={this.state.series}    
				  type="bar"
				  height={this.props.illustrationHeight}     
				/>
			</div>
		);
	}
}

export default TransferBarGraph;

