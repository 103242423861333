import React from 'react'
import './CSS/referralwelcomeimage.css' 
const ReferralWelcomeImage = () => {
  return (
    <>
        <figure className='referralWelcomeImageContainerImage'>
          <img 
            src='/images/dashboard/referral/Group 497.svg' 
            alt='a blue frame having content about referral, in its left part a title of invite friends and earn rewards below it its clarification or description below them the steps of referral and getting rewards, and in the right side part of frame there are 5 small images of persons around one big image of a person'
            draggable='false' 
          />   
        </figure>
         
        <figure className='referralWelcomeImageContainerRes'> 
          <img 
            src='/images/dashboard/referral/Group 497 (1).svg' 
            alt='a blue frame having content about referral, a title of invite friends and earn rewards below it its clarification or description below them the steps of referral and getting rewards: share your referral link, wait for your friend to order a card and receive your reward'  
            draggable='false' 
          />  
        </figure>
    </>
  )
}

export default ReferralWelcomeImage